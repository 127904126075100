<template>
    <b-row>
      <b-col sm="4">
        <Museum></Museum>
      </b-col>
      <b-col sm="4">
       <Faq></Faq>
      </b-col>
      <b-col sm="4">
        <Notice></Notice>
      </b-col>
    </b-row>
</template>
<script>
import Common from '@/mixins/common'
import Museum from '../museum'
import Faq from '../faq'
import Notice from '../notice'

export default {
  mixins: [Common],
  components: {
    Museum,
    Notice,
    Faq
  }
}
</script>
