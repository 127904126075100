<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill-opacity="1" d="M0,128L48,128C96,128,192,128,288,149.3C384,171,480,213,576,224C672,235,768,213,864,192C960,171,1056,149,1152,144C1248,139,1344,149,1392,154.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
    <span v-if="checkBn" >{{ $n(customerType.service.length) }} টি <br/> সেবা</span>
    <span v-else>{{ $n(customerType.service.length) }} <br/> Service</span>
  </div>
</template>

<script>
export default {
  props: {
    customerType: {
      type: Object,
      required: true
    },
    checkBn: {
      type: Boolean,
      required: true
    }
  }
}
</script>
