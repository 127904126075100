<template>
<div>
    <loadService v-if="loading"></loadService>
    <div class="main_service_section mb-3" v-else>
        <b-row>
            <b-col sm="8">
                <div class="main_service">
                <b-tabs content-class="mt-3" justified>
                    <b-tab :title="$t('portal.service_for_whom')" active>
                        <b-row>
                          <b-col v-for="(customerType, index) in serviceCustomerTypeList.slice(0, 6)" :key="index" sm="6">
                            <div class="service_item dropdown-7">
                              <b-card>
                                <router-link :to="`/portal/services?customer_type_id=` + customerType.id">
                                  <div class="item">
                                    <div class="item_left">
                                      <h4 class="title">
                                        {{ checkBn ? customerType.name_bn : customerType.name }}
                                      </h4>
                                      <img :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + customerType.image" class="d-none d-sm-inline-block"/>
                                      <ul>
                                        <li v-for="(service, index) in customerType.service.slice(0, 3)" :key="index">
                                          <router-link :to="`/portal/service-details?service_type=customerTypeList&customer_type_id=` + customerType.id + `&service_id=` + service.id" :title="checkBn ? service.name_bn : service.name">- {{ checkBn ? service.name_bn : service.name }}</router-link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="item_right">
                                      <service-number :customerType="customerType" :checkBn="checkBn !== 0"/>
                                    </div>
                                  </div>
                                </router-link>
                              </b-card>
                              <div class="service_dropdown_wrapper dropdown_menu--animated dropdown_menu-7">
                                <ul class="sub_menu_wrapper">
                                  <li v-for="(service, index) in customerType.service" :key="index"><router-link :to="`/portal/service-details?service_type=customerTypeList&customer_type_id=` + customerType.id + `&service_id=` + service.id" ><i class="fas fa-angle-right"></i> {{ checkBn ? service.name_bn : service.name }}</router-link></li>
                                </ul>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('portal.what_kind_of_service')">
                        <b-row>
                          <b-col v-for="(category, index) in categoryListView" :key="index" sm="6">
                            <div class="service_item dropdown-7">
                                <b-card>
                                  <router-link :to="`/portal/services?service_category_id=` + category.id">
                                    <div class="item">
                                      <div class="item_left">
                                      <h4 class="title">
                                          {{ checkBn ? category.name_bn : category.name }}
                                      </h4>
                                      <img
                                          :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + category.image"
                                          alt=""
                                      />
                                      <ul>
                                          <li v-for="(service, index) in category.service.slice(0, 3)" :key="index">
                                            <router-link :title="checkBn ? service.name_bn : service.name" :to="`/portal/service-details?service_type=categoryList&service_category_id=` + category.id + `&service_id=` + service.id">- {{ checkBn ? service.name_bn : service.name }}</router-link>
                                          </li>
                                      </ul>
                                      </div>
                                      <div class="item_right">
                                        <service-number :customerType="category" :checkBn="checkBn !== 0"/>
                                      </div>
                                    </div>
                                  </router-link>
                                </b-card>
                                <div class="service_dropdown_wrapper dropdown_menu--animated dropdown_menu-7">
                                  <ul class="sub_menu_wrapper">
                                    <li v-for="(service, index) in category.service" :key="index"><router-link :to="`/portal/service-details?service_type=categoryList&service_category_id=` + category.id + `&service_id=` + service.id" ><i class="fas fa-angle-right"></i> {{ checkBn ? service.name_bn : service.name }}</router-link></li>
                                  </ul>
                                </div>
                            </div>
                          </b-col>
                        </b-row>
                        <div class="mb-3 float-right">
                            <a @click="setOffsetCat" v-if="readmoreCat" href="javascript:" class="forward_link"
                            >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                            ></a>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('portal.which_org_service')">
                        <b-row>
                        <b-col v-for="(org, index) in orgListView" :key="index" sm="6">
                        <div class="service_item dropdown-7">
                            <b-card>
                              <router-link :to="`/portal/services?org_id=` + org.id">
                                <div class="item">
                                    <div class="item_left">
                                    <h4 class="title" :title="checkBn ? org.org_name_bn : org.org_name">
                                      {{ checkBn ? org.abbreviation_bn : org.abbreviation }}
                                    </h4>
                                    <img
                                        :src="commonServiceBaseUrl + org.logo"
                                        alt=""
                                    />
                                    <ul>
                                        <li v-for="(service, index) in org.service.slice(0, 3)" :key="index">
                                          <router-link :title="checkBn ? service.name_bn : service.name" :to="`/portal/service-details?service_type=orgList&org_id=` + org.id + `&service_id=` + service.id">- {{ checkBn ? service.name_bn : service.name }}</router-link>
                                        </li>
                                    </ul>
                                    </div>
                                    <div class="item_right">
                                      <service-number :customerType="org" :checkBn="checkBn !== 0"/>
                                    </div>
                                </div>
                              </router-link>
                            </b-card>
                            <div class="service_dropdown_wrapper dropdown_menu--animated dropdown_menu-7">
                              <ul class="sub_menu_wrapper">
                                <li v-for="(service, index) in org.service" :key="index"><router-link :to="`/portal/service-details?service_type=orgList&org_id=` + org.id + `&service_id=` + service.id" ><i class="fas fa-angle-right"></i> {{ checkBn ? service.name_bn : service.name }}</router-link></li>
                              </ul>
                            </div>
                        </div>
                        </b-col>
                        </b-row>
                        <div class="mb-3 float-right">
                            <a @click="setOffset" v-if="readmore" href="javascript:" class="forward_link"
                            >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                            ></a>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('agri_portal.about_service_portal')">
                        <b-row>
                            <b-col sm="12">
                              <h5 class="text-center text-success font-weight-bold mb-3">{{ $t('agri_portal.about_service_portal') }}</h5>
                              <div class="text-justify">
                                  <p v-if="$i18n.locale === 'en'" v-html="aboutPortal.description.length > 1300 ? (shortString(aboutPortal.description, 1300) + '...') : aboutPortal.description"></p>
                                  <p v-else v-html="aboutPortal.description_bn.length > 1300 ? (shortString(aboutPortal.description_bn, 1300) + '...') : aboutPortal.description_bn"></p>
                              </div>
                            </b-col>
                            <!-- <b-col v-for="(service, index) in allService.slice(0, 14)" :key="index" sm="6">
                                <div class="service_item">
                                  <b-card class="service_item_card">
                                    <router-link :to="`/portal/service-details?service_type=allService&service_id=` + service.id">
                                      <div class="item_all">
                                        {{ ($i18n.locale === 'bn') ? service.name_bn : service.name }}
                                      </div>
                                    </router-link>
                                  </b-card>
                                </div>
                            </b-col> -->
                            <b-col sm="12">
                              <div class="service_item all_service_list">
                                <div class="read_more_wrapper">
                                  <router-link to="/portal/about-service-portal" class="read_more">{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
                                </div>
                              </div>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
                </div>
            </b-col>
            <b-col sm="4">
                <div class="important_link_wrapper important_link__allService_wrapper">
                <b-tabs content-class="mt-3" justified>
                    <b-tab
                    :title="$t('portal.active_service') + ` (` + $n(totalService) + `)`"
                    class="service_info_wrapper"
                    active
                    >
                    <router-link :to="`/portal/service-details?service_type=allService&service_id=` + service.id" v-for="(service, index) in serviceList" :key="index" class="service_link">
                        {{ checkBn ? service.name_bn : service.name }}
                    </router-link >
                    <div class="read_more_wrapper">
                        <router-link to="/portal/all-services" class="read_more"
                        >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                        ></router-link>
                        <!-- <router-link to="/portal/all-services?status=active" class="read_more"
                        >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                        ></router-link> -->
                    </div>
                    </b-tab>
                    <!-- <b-tab
                    :title="$t('portal.upcoming_service')"
                    class="service_info_wrapper"
                    >
                    <router-link :to="`/portal/service-details?service_type=allService&service_id=` + service.id" v-for="(service, index) in serviceListUp" :key="index" class="service_link">
                        {{ checkBn ? service.name_bn : service.name }}
                    </router-link>
                    <div class="read_more_wrapper">
                        <router-link to="/portal/all-services?status=inavtive" class="read_more"
                        >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                        ></router-link>
                    </div>
                    </b-tab> -->
                </b-tabs>
                </div>
            </b-col>
        </b-row>
    </div>
</div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import Common from '@/mixins/common'
import loadService from '../loading/section_one'
import ServiceNumber from './ServiceNumber'

export default {
  mixins: [Common],
  components: {
    loadService,
    ServiceNumber
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      readmoreCat: true,
      offsetCat: 6,
      categoryListView: [],
      readmore: true,
      offset: 6,
      orgListView: [],
      orgList: [],
      orgServiceList: [],
      serviceCategoryList: [],
      loading: false,
      commonServiceBaseUrl: commonServiceBaseUrl
    }
  },
  computed: {
    aboutPortal () {
      return this.$store.state.Portal.serviceData.about_portal
    },
    totalService () {
      return this.$store.state.Portal.serviceData.total_services
    },
    allService () {
      return this.$store.state.Portal.serviceData.serviceList.filter(service => service.status === 1)
    },
    serviceList () {
      return this.$store.state.Portal.serviceData.serviceList.filter(service => (service.is_featured === 1 & service.status === 1)).slice(0, 7)
    },
    serviceListUp () {
      return this.$store.state.Portal.serviceData.serviceList.filter(service => (service.is_featured === 1 & service.status === 0)).slice(0, 7)
    },
    serviceCustomerTypeList () {
      return this.$store.state.Portal.serviceData.serviceCustomerTypeList.filter(item => item.status === 1)
    },
    serviceCategoryListAll () {
      return this.$store.state.Portal.serviceData.serviceCategoryList
    },
    orgListAll: function () {
      return this.$store.state.Portal.serviceData.orgList
    }
  },
  watch: {
    orgListAll: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setOrgList(newVal)
      }
    },
    serviceCategoryListAll: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setServiceCategoryList(newVal)
      }
    }
  },
  methods: {
    setServiceCategoryList (serviceCatList) {
      if (serviceCatList !== undefined && serviceCatList.length > 0) {
        this.serviceCategoryList = serviceCatList.filter(item => item.status === 1)
        this.categoryListView = this.serviceCategoryList.slice(0, this.offsetCat)
        return
      }
      this.categoryListView = []
    },
    setOrgList (orgList) {
      if (orgList !== undefined && orgList.length > 0) {
        this.orgList = orgList.filter(item => item.status === 0)
        this.orgListView = this.orgList.slice(0, this.offset)
        return
      }
      this.orgListView = []
    },
    setOffsetCat () {
      const nowOffset = this.offsetCat + 6
      this.offsetCat = nowOffset
      if (this.serviceCategoryList.length < nowOffset) {
        this.categoryListView = this.serviceCategoryList
        this.readmoreCat = false
      } else {
        this.categoryListView = this.serviceCategoryList.slice(0, nowOffset)
      }
    },
    setOffset () {
      this.orgListView = this.orgList
      this.readmore = false
    },
    getOrgServiceList (orgId) {
      return this.$store.state.Portal.serviceData.serviceList.filter(service => service.org_id === parseInt(orgId))
    },
    shortString (str, n) {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str
    },
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service')
      this.loading = false
      if (result.success) {
        this.$store.dispatch('Portal/mutateServiceData', result.data)
      }
    }
  }
}
</script>
