<template>
    <div class="museum_wrapper">
          <div class="portal_header">
            <h6 class="title">
              <!-- <i class="fas fa-globe-europe"></i> -->
              <img src="../../../../assets/images/portal/icons/agriculture-museum.svg" width="20" alt="">
              {{ $t('portal.virtual_museum') }}
            </h6>
            <a href="javascript:" class="forward_link"
              >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
            ></a>
          </div>
          <div class="portal_body museum_banner">
            <!-- <img
              src="../../../../assets/images/portal/museum-logo.png"
              width="60"
              alt=""
            /> -->
            <h2 class="title">{{ $t('portal.virtual_museum') }}</h2>
            <a :href="virtualMuseumBaseUrl" class="museum_btn" target="_blank">{{ $t('portal.visit_now') }}</a>
          </div>
        </div>
</template>
<script>
export default {
  data () {
    return {
      virtualMuseumBaseUrl: process.env.VUE_APP_VIRTUAL_APP_BASE_URL
    }
  }
}
</script>
<style scoped>
.main_wrapper .museum_wrapper .museum_banner {
  /* background-image: url("../../../../assets/images/portal/museum-bg.png"); */
  /* background-image: url("../../../../assets/images/portal/museum-bg-1.jpg"); */
  /* background-image: url("../../../../assets/images/portal/museum-bg-2.jpg"); */
  /* background-image: url("../../../../assets/images/portal/museum-bg-3.jpg"); */
  background-image: url("../../../../assets/images/portal/museum-bg-5.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
