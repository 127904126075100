<template>
<div>
    <FAQ v-if="loading"></FAQ>
    <div v-else class="museum_wrapper qna_section">
        <div class="portal_header">
        <h6 class="title">
            <!-- <i class="fas fa-globe-europe user_img"></i> -->
            <img src="../../../../assets/images/portal/icons/FAQ.svg" width="20" alt="">
            {{ $t('portal.Q_A') }}
        </h6>
        <router-link to="/portal/faq" class="forward_link">{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
        </div>
        <div class="portal_body">
          <div class="faq_wrapper" id="faqWrapper">
            <div :class="single_faq.answer === index ? `card active_card` : `card`" v-for="(item, index) in datas" :key="index">
              <div class="card-header" id="q1">
                <h2 class="que_wrapper">
                  <i class="far fa-question-circle"></i>
                  <button @click="(single_faq.answer === index) ? single_faq.answer ='' : single_faq.answer = index" class="btn btn-link btn-block text-left p-0" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {{ ($i18n.locale === 'bn') ? item.title_bn : item.title }}
                  </button>
                </h2>
              </div>
              <div v-if="single_faq.answer === index" id="collapseOne" class="collapse show" aria-labelledby="q1" data-parent="#faqWrapper">
                <div class="card-body">
                  <span class="ans"> {{ $t('portal.answer') }}- </span> {{ ($i18n.locale === 'bn') ? shortString(item.description_bn, 200) : shortString(item.description, 200) }}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../../config/api_config'
import FAQ from './loading/section_two/q-a'
import Common from '@/mixins/portal/common'

export default {
  mixins: [Common],
  components: {
    FAQ
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      loading: false,
      datas: {},
      single_faq: {
        answer: 0
      }
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/qa-home')
      this.loading = false
      if (result.success) {
        this.datas = result.data.filter(item => item.status === 1)
      }
    }
  }
}
</script>
