<template>
<div>
    <loadNotice v-if="loading"></loadNotice>
    <div v-else class="museum_wrapper">
        <div class="portal_header">
        <h6 class="title">
            <!-- <i class="fas fa-globe-europe"></i> -->
            <img src="../../../../assets/images/portal/icons/notice.svg" width="16" style="vertical-align: top;" alt="">
            {{ $t('portal.notice_list') }}
        </h6>
        <router-link to="/portal/notice" class="forward_link">{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
        </div>
        <div class="portal_body">
        <div v-for="(item, index) in notice" :key="index" class="qna_wrapper notice_wrapper">
            <div class="item_header">
            <i class="far fa-file-pdf user_img"></i>
            <div class="user_name">
                <p class="name">{{ (checkBn) ? item.title_bn : item.title }}</p>
                <p class="designation mb-0">
                {{ checkBn ? shortString(item.description_bn, 150) : shortString(item.description) }}
                </p>
                <router-link to="/portal/notice" class="read_more"
                >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                ></router-link>
            </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../../config/api_config'
import Common from '@/mixins/portal/common'

export default {
  mixins: [Common],
  components: {
    loadNotice: () => import('./loading/section_two/notice')
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      notice: {},
      loading: false
    }
  },
  computed: {
    checkBn () {
        return (this.$i18n.locale === 'bn') ? 1 : 0
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/notice-home')
      this.loading = false
      if (result.success) {
        this.notice = result.data.filter(item => item.status === 1)
      }
    }
  }
}
</script>
