<template>
    <b-row class="news_section">
      <b-col sm="8">
        <div v-if="loading">
          <NewsLoad/>
        </div>
        <div v-else class="news_wrapper museum_wrapper">
          <div class="portal_header">
            <h6 class="title">
              <!-- <i class="fas fa-globe-europe"></i> -->
              <img src="../../../../../assets/images/portal/icons/news.svg" width="20" alt="">
              {{ $t('portal.agriculture_news') }}
            </h6>
            <router-link to="/portal/news" class="forward_link">{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
          </div>
          <div class="portal_body">
            <div v-if="datas[0]" class="latest_news_wrapper mb-3">
              <img
                :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + datas[0].image_bn"
                class="cover_img"
                width="260"
                alt=""
                loading="lazy"
              />
              <div class="news_content">
                <h4 class="title">{{ $i18n.locale === 'bn' ? datas[0].title_bn : datas[0].title }}</h4>
                <p class="description">
                  {{ $i18n.locale === 'bn' ? shortString(datas[0].description_bn, 200) : shortString(datas[0].description)}}
                </p>
                <router-link :to="`/portal/news-details/` + datas[0].id" class="read_more"
                  >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                ></router-link>
              </div>
            </div>
            <b-row>
              <b-col v-for="(item, index) in datas.filter((item, index)=>index!==0)" :key="index" sm="4">
                <div class="latest_news_wrapper old_post">
                  <img
                    :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + item.image_bn"
                    width="64"
                    alt=""
                  />
                  <div class="news_content">
                    <h6 class="title">{{ ($i18n.locale === 'bn') ? item.title_bn : item.title }}</h6>
                    <!-- <small class="auth">কৃষিবিদ মোঃ সোহরাব হোসেন</small> -->
                    <p class="description">
                      {{ ($i18n.locale === 'bn') ? truncate(item.description_bn, 100) : truncate(item.description, 100) }}
                    </p>
                    <router-link :to="`/portal/news-details/` + item.id" class="read_more"
                      >{{ $t('portal.read_more') }} <i class="fas fa-long-arrow-alt-right"></i
                    ></router-link>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="portal_header mt-3">
                  <h6 class="title">
                    <!-- <i class="fas fa-globe-europe"></i> -->
                    <img src="../../../../../assets/images/portal/icons/agriculture-museum.svg" width="20" alt="">
                    {{ $t('portal.krishi_batayon') }}
                  </h6>
                </div>
                <div class="info_panel_wrapper">
                  <a href="http://krishi.gov.bd/" target="_blank">
                    <img src="../../../../../assets/images/portal/krishi-batayon.png" alt="krishi-batayon">
                  </a>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col sm="4">
        <div class="important_link_wrapper">
          <b-tabs content-class="mt-3" justified>
            <b-tab :title="$t('portal.org_link')" active>
              <b-list-group style="max-height:392px;overflow-y:scroll">
                <b-list-group-item v-for="(org, index) in orgList" :key="index">
                  <a target="_blank" :href="`http://` + org.website_url">
                    <img v-if="org.logo" :src="commonServiceBaseUrl + org.logo" width="24" alt="">
                    <img v-else src="../../../../../assets/images/logo.png" width="24" alt="">
                    {{ checkBn ? org.org_name_bn : org.org_name }}
                  </a>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
            <b-tab :title="$t('portal.useful_link')">
              <b-list-group>
                <b-list-group-item>
                  <b-link target="_blank" href="http://114.130.119.102:8090/">
                    <img src="../../../../../assets/images/logo.png" width="24" :alt='$t("portal.new_links")'>
                    {{ $t("portal.new_links") }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link target="_blank" href="https://mopa.gov.bd/">
                    <img src="../../../../../assets/images/logo.png" width="24" alt="">
                     {{ $t("portal.im_ln_mopa") }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link target="_blank" href="http://pmis.mopa.gov.bd/">
                    <img src="../../../../../assets/images/logo.png" width="24" alt="">
                    {{ $t("portal.im_ln_pmis") }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link target="_blank" href="https://mof.gov.bd/">
                    <img src="../../../../../assets/images/logo.png" width="24" alt="">
                    {{ $t("portal.im_ln_mof") }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link target="_blank" href="https://cabinet.gov.bd/">
                    <img src="../../../../../assets/images/logo.png" width="24" alt="">
                    {{ $t("portal.im_ln_cabinet") }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link target="_blank" href="https://pmo.gov.bd/">
                    <img src="../../../../../assets/images/logo.png" width="24" alt="">
                    {{ $t("portal.im_ln_pmo") }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link target="_blank" href="https://bangladesh.gov.bd/index.php">
                    <img src="../../../../../assets/images/logo.png" width="24" alt="">
                    {{ $t("portal.im_ln_national_batayon") }}
                  </b-link>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import Common from '@/mixins/portal/common'
import NewsLoad from '../loading/section_three/news'

export default {
  mixins: [Common],
  components: {
    NewsLoad
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      datas: [],
      orgList: [],
      loading: false
    }
  },
  computed: {
    orgListAll () {
      return this.$store.state.Portal.serviceData.orgList
    }
  },
  watch: {
    orgListAll: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setOrgList(newVal)
      }
    }
  },
  methods: {
    setOrgList (orgList) {
      if (orgList !== undefined && orgList.length > 0) {
        this.orgList = orgList.filter(item => item.status === 0)
        return
      }
      this.orgList = []
    },
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/news-home')
      this.loading = false
      if (result.success) {
        this.datas = result.data
      }
    },
    truncate (str, n) {
      return (str.length > n) ? str.substr(0, n - 1) + '' : str
    }
  }
}
</script>
<style scoped>
.main_wrapper .news_section .news_wrapper .info_panel_wrapper {
  position: relative;
  background-image: url("../../../../../assets/images/portal/krishi-batayon-bg.png");
  background-size: cover;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  border: 2px solid #333;
  border-radius: 10px;
}
.main_wrapper .news_section .news_wrapper .info_panel_wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
    border-radius: 8px;
}
.main_wrapper .news_section .news_wrapper .info_panel_wrapper img {
    position: relative;
    z-index: 10;
    background: #fff;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 3px 4px 5px #000;
}
</style>
