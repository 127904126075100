<template>
  <b-container class="main_wrapper home_wrapper">
    <!-- Sercice Section -->
      <Service></Service>
    <!-- Museum Section -->
      <Museum></Museum>
    <!-- News Section -->
      <News></News>
  </b-container>
</template>
<script>
import Common from '@/mixins/common'
import Museum from '../components/home/sections/section_two'
import News from '../components/home/sections/section_three'
import Service from '../components/home/sections/section_one'

export default {
  mixins: [Common],
  components: {
    Museum,
    News,
    Service
  }
}
</script>
